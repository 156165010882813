import React from 'react'
import { cn } from '~/utils/cn'
import * as styles from './Button.styles'

export type ButtonProps = styles.Variants &
  React.ComponentPropsWithRef<'button'>

const Button = React.forwardRef(
  (
    {
      className,
      color,
      rounded,
      variant,
      fullWidth,
      size,
      ...restButtonProps
    }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        className={cn(
          styles.buttonVariants({ rounded, color, fullWidth, variant, size }),
          className
        )}
        {...restButtonProps}
      />
    )
  }
)

export default Button
